import Vue from 'vue';
import VueRouter from 'vue-router';
// ------+---------+---------+---------+---------+---------+---------+---------E
Vue.use(VueRouter);
// ======+=========+=========+=========+=========+=========+=========+=========E
const routes = [
  // ----+---------+---------+---------+---------+---------+---------+---------E
  // TOPページ
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    path: '/',
    name: 'Home page',
    component: () => import('@/pages/home.page'),
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  // Zaif INO概要ページ
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    path: '/about',
    name: 'About',
    component: () => import('@/pages/about.page'),
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  // カテゴリページ
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    path: '/Category/:id',
    name: 'Category Page',
    component: () => import('@/pages/category.page'),
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  // プロジェクト（漫画家、アーティスト）ページ
  // ----+------------------+---------+---------+---------+---------+---------E
  {
    path: '/Creator/:id',
    name: 'Creator Page',
    component: () => import('@/pages/creator.page'),
    beforeEnter: (to, from, next) => {
      const creatorId = to.params.id;
  
      if (creatorId === '242') {
        next({ path: '/Series/241' });
      } else {
        next();
      }
    } // End of beforeEnter: (to, from, next) => {...}
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  // シリーズページ
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    path: '/Series/:id',
    name: 'Series Page',
    component: () => import('@/pages/series.page'),
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  // エディションページ
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    path: '/Edition/:id',
    name: 'Edition Page',
    component: () => import('@/pages/edition.page'),
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  // NFT詳細ページ
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    path: '/NFT/:id',
    name: 'NFT Details Page',
    component: () => import('@/pages/detail.page'),
    beforeEnter: (to, from, next) => {
      const id = to.params.id;
      
      // NFTカレーの場合、NFTカレー2にリダイレクト
      if (id === '68') {
        next({ path: '/NFT/196' });
      // メンバーズウォレットカードの場合、メンバーズウォレットカード2にリダイレクト
      } else if (id === '193') {
        next({ path: '/NFT/202' });
      } else {
        next();
      } // End of if (id === '68') {...} else if ... else {...}
    } // End of beforeEnter: (to, from, next) => {...}
  },
  //-----+---------+---------+---------+---------+---------+---------+---------E
  // 検索結果ページ
  //-----+---------+---------+---------+---------+---------+---------+---------E
  {
    path: '/SearchResult',
    name: 'NFT Search Result Page',
    component: () => import('@/pages/search-result.page'),
  },
  //-----+---------+---------+---------+---------+---------+---------+---------E
  // TOPバナー管理
  //-----+---------+---------+---------+---------+---------+---------+---------E
  {
    path: '/manage-topbanner',
    name: 'TOP banner',
    component: () => import('@/pages/manage-topbanner.page'),
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  // 管理ページ
  // ----+---------+---------+---------+---------+---------+---------+---------E
  {
    path: '/manage',
    component: () => import('@/pages/manage.page'),
    children: [
      // +---------+---------+---------+---------+---------+---------+---------E
      // 「プロジェクトを探す」ページ
      // +---------+---------+---------+---------+---------+---------+---------E
      {
        path: 'projects',
        name: 'Project Management Page',
        component: () => import('@/pages/project-management.page'),
      },
      // +---------+---------+---------+---------+---------+---------+---------E
      // 「プロジェクトを見る」ページ
      // +---------+---------+---------+---------+---------+---------+---------E
      {
        path: 'projects/:id',
        component: () => import('@/pages/project-detail.page'),
        name: 'Project detail page',
      },
      // +---------+---------+---------+---------+---------+---------+---------E
      // 「プロジェクトの編集」ページ、既登録ＮＦＴを編集するページ
      // +---------+---------+---------+---------+---------+---------+---------E
      {
        path: 'projects/:id/edit',
        component: () => import('@/pages/edit-project.page'),
      },
      // +---------+---------+---------+---------+---------+---------+---------E
      // プロジェクト（ＮＦＴ）を新規作成するページ
      // +---------+---------+---------+---------+---------+---------+---------E
      {
        path: 'user-projects/create',
        component: () => import('@/pages/create-project.page'),
        name: 'Create project page',
      },
      // +---------+---------+---------+---------+---------+---------+---------E
      // 「カテゴリを探す」ページ
      // +---------+---------+---------+---------+---------+---------+---------E
      {
        path: 'categories',
        name: 'Category Management Page',
        component: () => import('@/pages/category-management.page'),
      },
      // +---------+---------+---------+---------+---------+---------+---------E
      // 「カテゴリ・プロジェクト・シリーズ・エディションの編集」ページ
      // +---------+---------+---------+---------+---------+---------+---------E
      {
        path: 'categories/:id/edit',
        component: () => import('@/pages/edit-category.page'),
      },
      // +---------+---------+---------+---------+---------+---------+---------E
      // カテゴリを新規作成するページ
      // +---------+---------+---------+---------+---------+---------+---------E
      {
        path: 'user-categories/create',
        component: () => import('@/pages/create-category.page'),
        name: 'Create category page',
      },
      // +---------+---------+---------+---------+---------+---------+---------E
      {
        path: 'user-projects/:id',
        component: () => import('@/pages/user-project.page'),
        children: [
          {
            path: '',
            alias: ['basic-info'],
            component: () => import('@/pages/basic-info.page'),
          },
          // ------+---------+---------+---------+---------+---------+---------E
          {
            path: 'sale-info',
            component: () => import('@/pages/sale-info.page'),
          },
        ], // End of children: [...]
      },
      // +---------+---------+---------+---------+---------+---------+---------E
      // Noticeページ
      // +---------+---------+---------+---------+---------+---------+---------E
      {
        path: 'notice',
        name: 'Project Notice Page',
        component: () => import('@/pages/notice.page'),
      },
      // +---------+---------+---------+---------+---------+---------+---------E
      // PR Frameページ
      // +---------+---------+---------+---------+---------+---------+---------E
      {
        path: 'promote',
        name: 'Project Promote Page',
        component: () => import('@/pages/promote.page'),
      },
    ], // End of children: [...]
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  { path: '*', component: () => import('@/pages/404.page') },
]; // End of const routes = [...]
// ======+=========+=========+=========+=========+=========+=========+=========E
const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
}); // End of const router = new VueRouter({...})
// ======+=========+=========+=========+=========+=========+=========+=========E
export default router;
