import cloneDeep from "lodash-es/cloneDeep";
import {
  UPDATE_CATEGORIES_LIST    ,
  UPDATE_CATEGORY_DETAIL_MAP,
  UPDATE_SELECTED_CATEGORY  ,
  UPDATE_TOTAL_CATEGORIES   ,
} from "./constants.categories.store";
// ======+=========+=========+=========+=========+=========+=========+=========E
export default {
  [UPDATE_CATEGORIES_LIST](state, payload) {
    state.categoriesList = cloneDeep(payload);
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  [UPDATE_CATEGORY_DETAIL_MAP](state, payload) {
    const oldMap = cloneDeep(state.categoryDetailMap);
    payload.forEach((item) => {
      const { id } = item;

      oldMap[id] = { ...item };
    });
    state.categoryDetailMap = cloneDeep(oldMap);
  }, // End of [UPDATE_CATEGORY_DETAIL_MAP](state, payload) {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  [UPDATE_TOTAL_CATEGORIES](state, payload) {
    state.totalCategories = payload;
  },
  // ----+---------+---------+---------+---------+---------+---------+---------E
  [UPDATE_SELECTED_CATEGORY](state, payload) {
    state.selectedCategory = { ...payload };
  },
}; // End of export default {...}
