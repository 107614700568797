import {
    // UPDATE_STATUS           , // TOPページのステイタス選択コンボボックスの値
    UPDATE_OBJECT_CATEGORY  ,
    UPDATE_STRING_FOR_SEARCH,
    CLEAR_EXTRACT_CONDITION,
} from "./constants.extractcondition.store";
// ======+=========+=========+=========+=========+=========+=========+=========E
export default {
    // home.page.vue の NFT Status コンボボックスの値を state にセット。
    // setStatusInHomePage({ commit }, string_status) {
        // commit(UPDATE_STATUS, string_status);
        // alert(`END  : setStatusInHomePage(string_status=${string_status}))` +
        //       `: mapActions.etractcondition.store.js`
        // );
    // }, // End of setStatusInHomePage({ commit }, string_status) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // ヘッダのカテゴリコンボボックスと検索テキストボックスの値を state にセット。
    setHeaderCondition({ commit }, {object_category, string_for_search}) {
        commit(UPDATE_OBJECT_CATEGORY  , object_category  );
        commit(UPDATE_STRING_FOR_SEARCH, string_for_search);
        // alert(`END  : setHeaderCondition(` +
        //   `object_category=${JSON.stringify(object_category)}, ` +
        //   `string_for_search=${string_for_search}): ` +
        //   `action.headercondition.store.js`);
    }, // End of setHeaderCondition({ commit }, {category_id, name}) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    // ヘッダのカテゴリコンボボックスと検索テキストボックスの値をクリア。
    clearHeaderCondition({ commit }) {
        commit(CLEAR_EXTRACT_CONDITION);
        // alert(`END: clearHeaderCondition: action.headercondition.store.js`);
    }, // End of setHeaderCondition({ commit }, {category_id, name}) {...}
}; // End of export default {...}
