import actions   from "./actions.categories.store"  ;
import mutations from "./mutations.categories.store";
import state     from "./state.categories.store"    ;
// ======+=========+=========+=========+=========+=========+=========+=========E
export default {
  namespaced: true,
  state,
  actions,
  mutations,
}; // End of export default {...}
