import actions from './actions.allowance.store';
import mutations from './mutations.allowance.store';
import state from './state.allowance.store';
import * as getters from './getters.allowance.store';

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
