import { getCategoriesList, getCategories } from '@/services/categories';
// import { getLink } from '@/utils/link.util';
import { getFileName } from '@/utils/common.util';
import {
  UPDATE_CATEGORIES_LIST,
  UPDATE_SELECTED_CATEGORY,
  UPDATE_TOTAL_CATEGORIES,
  UPDATE_CATEGORY_DETAIL_MAP,
} from './constants.categories.store';
// ======+=========+=========+=========+=========+=========+=========+=========E
export default {
  // name_en 又は name_ja が指定文字列を含むレコードを
  // テーブル categories から取得。
  async fetchCategories({ commit }, { limit, offset, search, order_by, sort_order }) {
    // alert(`*** START: fetchCategories: actions.categories.store.js ***`);
    const data = await getCategoriesList({
      limit     : limit     ,
      offset    : offset    ,
      name_like : search    ,
      order_by  : order_by  ,
      sort_order: sort_order,
    }); // End of const data = await getCategoriesList({...})
    // alert(`data=${JSON.stringify(data)}: fetchCategories: default`);
    commit(UPDATE_CATEGORIES_LIST , data?.items);
    commit(UPDATE_TOTAL_CATEGORIES, data?.total);
    // alert(`*** END  : fetchCategories: actions.categories.store.js ***`);
  }, // End of async fetchCategories({ commit }, { limit, offset, search }) {...}
  // ----+---------+---------+---------+---------+---------+---------+---------E
  // 指定 id のレコードをテーブル categories から取得。
  async fetchCategoryDetail({ commit }, id) {
    // alert(`*** START: fetchCategoryDetail(id=${id}): actions.categories.store.js ***`);
    let result = await getCategories({id: id});
    // alert(`*** result=${JSON.stringify(result)}: actions.categories.store.js ***`);
    result = result.items[0];
    const data = {
      ...result,
      thumbnail_uploaded_name: getFileName(result.thumbnail_url),
      banner_uploaded_name   : getFileName(result.banner_url),
    }; // End of const data = {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    commit(UPDATE_SELECTED_CATEGORY  ,  data );
    commit(UPDATE_CATEGORY_DETAIL_MAP, [data]);
    // alert(`*** END  : fetchCategoryDetail: actions.projects.store.js ***`);
  }, // End of async fetchCategoryDetail({ commit }, id) {...}
}; // End of export default {...}
