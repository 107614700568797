import http from './axios';
import { API_VERSION_1_PREFIX } from '@/env';
// ======+=========+=========+=========+=========+=========+=========+=========E
// カテゴリリストを取得する。
//-------+---------+---------+---------+---------+---------+---------+---------E
export const getCategoriesList = async ({
  limit      = 10,
  offset     =  0,
  name_like  = '',
  order_by   = 'created_at',
  sort_order = 'desc'
}) => {
  try {
    // alert(`*** START: getCategoriesList in categories.js ***`);
    const objectParams = {
      limit     : limit     ,
      offset    : offset    ,
      name_like : name_like ,
      order_by  : order_by  ,
      sort_order: sort_order,
    }; // End of objectParmas = {...}
    // alert(`objectParams=${JSON.stringify(objectParams)}: getCategoriesList: categories.js`);
    const response = await http.get(`${API_VERSION_1_PREFIX}/categories`, {
      params: objectParams
    });
    // --+---------+---------+---------+---------+---------+---------+---------E
    // alert(`*** END  : getCategoriesList in categories.js ***`);
    return response;
  } catch (error) {
    console.error(error);
    return null;
  } // End of try {...} catch (error) {...}
}; // End of export const getCategoriesList = async (...) => {...}
// ======+=========+=========+=========+=========+=========+=========+=========E
// バックエンド mint-nft-backend DB テーブル categories にレコード追加。
export const createCategory = async (formData) => {
  const data = await http.post(`${API_VERSION_1_PREFIX}/categories`, formData);
  return data;
};
// ======+=========+=========+=========+=========+=========+=========+=========E
// バックエンド mint-nft-backend DB テーブル categories からカテゴリ情報を
// 取得する。結果は一レコード一オブジェクトの配列。
// id を指定して一カテゴリ情報取得、又は、id を undefined にして全カテゴリ情報取得。
//-------+---------+---------+---------+---------+---------+---------+---------E
export const getCategories = async (objectArg) => {
  try {
    // alert(`*** getCategories(${JSON.stringify(objectArg)}): categories.js ***`);
    // alert(`objectArg?.id=${objectArg?.id}`);
    const response = await http.get(`${API_VERSION_1_PREFIX}/categories`, {
      params: {
        // id が undefined でない場合、本オブジェクトの他の属性は無視して、
        // id をキーとして categories のレコードオブジェクトを取得。
        id: objectArg?.id,
        type: objectArg?.type, // 0:カテゴリ、1:クリエイター、2:シリーズ、3:エディション
        hierarchy: objectArg?.hierarchy,
        name_en: objectArg?.name_en,
        recommendation_top_as_project:
          objectArg?.recommendation_top_as_project,
        recommendation_categorytop_as_project:
          objectArg?.recommendation_categorytop_as_project,
        limit: objectArg?.limit, // 1ページのカテゴリ数。現在12。
        offset: objectArg?.offset, //2ページ目以降取得用。
        is_deleted: objectArg?.is_deleted,
      } // End of params: {...}
    }); // End of const response = await http.get(..., {...})
    //alert(`*** response=${JSON.stringify(response)}: categories.js ***`);
    if (!response) throw 'No content found';
    return response;
  } catch (error) {
    alert(`ERROR: ${JSON.stringify(error)}`)
    return [];
  } // End of try {...} catch(...) {...}
}; // End of export const getCategories = async (objectArg) => {...}
// ------+---------+---------+---------+---------+---------+---------+---------E
export const editCategory = async (categoryId, formData) => {
  const res = await http.post(`${API_VERSION_1_PREFIX}/categories/${categoryId}`, formData);
  return res;
}; // End of export const editCategory = async (categoryId, formData) => {...}
