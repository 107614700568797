import {
    // UPDATE_STATUS,  // TOPページのステイタス選択コンボボックスの値。
    UPDATE_OBJECT_CATEGORY  ,
    UPDATE_STRING_FOR_SEARCH,
    CLEAR_EXTRACT_CONDITION,
} from "./constants.extractcondition.store";
// ======+=========+=========+=========+=========+=========+=========+=========E
export default {
    // [UPDATE_STATUS](state, payload) {
        // alert(`${UPDATE_STATUS}(payload=${JSON.stringify(payload)}): ` +
        //       `mutations.extractcondition.store.js`
        // );
        // state.string_status = payload;
        // alert(`state.string_status=${state.string_status}: ` +
        //       `mutations.extractcondition.store.js`);
    // }, // End of [UPDATE_STATUS](state, payload) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    [UPDATE_OBJECT_CATEGORY](state, payload) {
        // alert(`${UPDATE_OBJECT_CATEGORY}(payload=` +
        //       `${JSON.stringify(payload)}): ` +
        //       `mutations.extractcondition.store.js`
        // );
        state.object_category = payload;
        // alert(`state.category_id=${state.category_id}: ` +
        //       `mutations.extractcondition.store.js`);
    }, // End of [UPDATE_OBJECT_CATEGORY](state, payload) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    [UPDATE_STRING_FOR_SEARCH](state, payload) {
        // alert(`${UPDATE_STRING_FOR_SEARCH}(payload=${payload}): ` +
        //       `mutations.extractcondition.store.js`
        // );
        state.string_for_search = payload;
        // alert(`state.string_for_search=${state.string_for_search}: ` +
        //       `mutations.extractcondition.store.js`);
    }, // End of [UPDATE_STRING_FOR_SEARCH](state, payload) {...}
    // --+---------+---------+---------+---------+---------+---------+---------E
    [CLEAR_EXTRACT_CONDITION](state) {
        // alert(`${CLEAR_EXTRACT_CONDITION}: ` +
        //       `mutations.extractcondition.store.js`
        // );
        state.object_category = {
            // テーブル categories の列 id。
            category_id  : 0, // 「全て」
            // テーブル categories の列 name_en。
            category_name: 'ALL',
            // コンボボックスに表示するカテゴリ名。
            // 後で上書きするのでここでは何をセットしても良い。
            category_display: '',
            // 通し番号。カテゴリカルーセルの画像選択に使う。
            category_sequence: 0,
        }; // End of state.object_category = {...}
        state.string_for_search = '';
        // alert(`state.object_category=` +
        //         `${JSON.stringify(state.object_category)}, ` +
        //         `state.string_for_search=${state.string_for_search}: `+
        //         ` [CLEAR_EXTRACT_CONDITION]: ` +
        //         `mutations.extractcondition.store.js`
        // );
    }, // End of [CLEAR_EXTRACT_CONDITION](state, payload) {...}
}; // End of export default {...}
