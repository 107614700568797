import BigNumber from "bignumber.js";

BigNumber.set({
  ROUNDING_MODE: BigNumber.ROUND_DOWN,
  EXPONENTIAL_AT: [-50, 50],
  RANGE: 800,
});

const compare = (method) => (inputA) => (inputB) =>
  new BigNumber(inputA)[method](inputB);

const shift = (number, decimals) => {
  const dec = new BigNumber(decimals).toNumber();
  return new BigNumber(number).shiftedBy(dec).dp(0).toString();
};
const unshift = (number, decimals) => {
  const dec = new BigNumber(decimals).toNumber();
  return new BigNumber(number).shiftedBy(-dec).toString();
};
export { BigNumber, compare, shift, unshift };
