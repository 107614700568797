import actions   from "./actions.extractcondition.store"  ;
import mutations from "./mutations.extractcondition.store";
import state     from "./state.extractcondition.store"    ;
// ------+---------+---------+---------+---------+---------+---------+---------E
export default {
    namespaced: true,
    state           ,
    actions         ,
    mutations       ,
}; // End of exort default {...}
