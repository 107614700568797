import http from "./axios";
import { API_VERSION_1_PREFIX } from "@/env";

export const login = async (address) => {
  try {
    const { token: data } = await http.post(
      `${API_VERSION_1_PREFIX}/auth/login`,
      {
        wallet_address: address,
      }
    );
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
