export default () => ({
    // string_status    : '', // TOPページのステイタス選択コンボボックスの値
    onject_category  : {
        category_id: 0,
        category_name: '',
        category_display: '',
        category_sequence: 0,
    }, // End of onject_category  : {...}
    string_for_search: '',
}); // End of export default () => ({...})
  