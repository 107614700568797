import { getAddress } from '@ethersproject/address';

export function truncate(str, n, frontChars, backChars, separator) {
  /**
   * str: Input string
   * n: Number of character want to display
   * frontChars: Number of characters in front of separator
   * backChars: Number of characters in back of separator
   * seperator: Symbol want to display, default "..."
   */
  const sep = separator || '...';
  const sepLen = sep.length;
  if (str.length < n - sepLen) {
    return str;
  }
  return str.substr(0, frontChars) + sep + str.substr(str.length - backChars);
}

export function isAddress(value) {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

export const getFileName = (url) => {
  if (url) {
    const splitted = url.split('/');
    return splitted[splitted.length - 1];
  }
  return '';
};

export function formatNumberWithCommas(number) {
  if (typeof number !== 'number') {
    return number;
  }

  // 数値を指数表記から通常の小数表記に変換
  const fixedNumber = number.toFixed(10).replace(/\.?0+$/, '');

  // 数値を整数部分と小数部分に分割
  const [integerPart, decimalPart] = fixedNumber.split('.');

  // 整数部分にカンマを追加
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // 小数部分が存在する場合は結合して返す
  return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
}